<template>
  <div class="deviceSkim">
    <div
      class="topView"
      style="display: block; padding-bottom: 20px;min-width: 1128px;width:100%"
    >
      <div class="searchBox" style="display: block">
        <el-form
          class="deviceForm"
          :model="bindForm"
          ref="bindForm"
          :inline="true"
        >
          <el-form-item
            label="车牌号或设备IMEI"
            prop="VehicleCarNumber"
            label-width="130px"
          >
            <el-input
              v-model="bindForm.VehicleCarNumber"
              placeholder="请输入车牌号或设备IMEI"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="选择时间范围" prop="time" label-width="100px">
            <el-date-picker
              :default-time="defaultTime"
              style="width: 380px"
              v-model="bindForm.time"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="search('bindForm')"
              >搜索</el-button
            >
            <el-button
              type="primary"
              size="medium"
              @click="resetForm('bindForm')"
              >清空</el-button
            >
            <el-button
              icon="el-icon-download"
              type="primary"
              size="medium"
              @click="download"
              >导出轨迹</el-button
            >
          </el-form-item>
        </el-form>
        <div class="mileage" v-if="ismileage">
          <span
            ><strong>轨迹里程：</strong
            >{{ Number(mileageNum).toFixed(2) }}KM</span
          >
          <span style="margin-left: 10px" v-if="isBD"
            ><strong>北斗里程：</strong
            >{{ Number(mileageNumBD).toFixed(2) }}KM</span
          >
        </div>
      </div>
      <!--详情弹框-->
      <!-- <div>
      <el-dialog width="30%" :visible.sync="outerVisible"> -->
      <!-- <div class="outerBox">
          <div class="top">
            <div class="device">物联设备:{{ deviceInfo.IMEI ? deviceInfo.IMEI : '暂无' }}</div>
            <div>设备所有人:{{deviceInfo.EquipOwnerName ? deviceInfo.EquipOwnerName : '暂无'}}</div>
          </div>
          <div>车牌号/司机手机：{{deviceInfo.VehicleNo ? deviceInfo.VehicleNo: '暂无'}}/{{deviceInfo.DriverPhone ? deviceInfo.DriverPhone : '暂无'}}</div>
        </div> -->
      <!-- <el-dialog width="55%" title="设备详情" :visible.sync="innerVisible" center append-to-body>
          <div class="innerBox" style="display: flex">
            <div class="info" style="width: 90%">
              <el-form :inline="true" ref="form" label-width="80px">
                <div>
                  <el-form-item label="设备IMEI" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.IMEI" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="车辆名称">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.VehicleName" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="设备名称" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.EquipName" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="车牌号码">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.VehicleNo" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="设备型号" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.EquipNO" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="车辆品牌">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.VehicleBrand" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="使用范围" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.LimitUse" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="车架号">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.VehicleFrameNumber" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="到期时间" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.ValidDate" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="发动机号">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.VehicleEngine" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="激活时间" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.ActiveDate" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="司机姓名">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.DriverName" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="SIM卡" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.SIMCard" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="司机电话">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.DriverPhone" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="设备类型" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.EquipTypeName" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="车速">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.Speed" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="位置信息" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.Location" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="里程统计">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.MileageTotal" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="精准定位" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.PrecisePosition" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="方位角度">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.Angle" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="定位类型" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.LocationTypeID" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="电压/电量">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.Powerfull" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
                <div>
                  <el-form-item label="备注" style="margin-right: 10%">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.Note" :disabled="true">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="监测时长">
                    <el-input placeholder="请输入内容" v-model="deviceInfo.MonitorDuration" :disabled="true">
                    </el-input>
                  </el-form-item>
                </div>
              </el-form>
            </div>
            <div class="state" style="width: 20%">
              <div v-for="item in stateList" :key="item.id" style="margin-bottom: 40px">
                <div>{{ item.name }}</div>
                <div style="
                    display: flex;
                    align-items: center;
                    margin-top: 30px;
                    margin-left: 20px;
                  ">
                  <span class="circle" :style="
                      item.isActive == true ? 
                      'display: inline-block; width: 25px; height: 25px; background: green;border-radius: 50%;'
                      : 'display: inline-block; width: 25px; height: 25px; background: red;border-radius: 50%;'
                    "></span><span style="margin-left: 14px">启用</span>
                </div>
              </div>
              <div>
                <div>GPS信号强度</div>
                <div style="margin-top: 20px;padding-left: 20px;" class="strengthen">
                  <img class="imgs" style="margin-right: 4px;" src="../../../assets/static/1.png" />
                  <img class="imgs" style="margin-right: 3px;" src="../../../assets/static/2.png" />
                  <img class="imgs" style="margin-right: 4px;" src="../../../assets/static/3.png" />
                  <img class="imgs" style="margin-right: 4px;" src="../../../assets/static/4.png" />
                  <img class="imgs" style="margin-right: 4px;" src="../../../assets/static/5.png" />
                </div>
              </div>
            </div>
          </div>
        </el-dialog> -->
      <!-- <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="innerVisible = true">查看详情</el-button>
        </div> -->
      <!-- </el-dialog>
    </div> -->
      <div v-loading="loading" style="position: relative">
        <div id="container" style="width:auto;height:77vh;"></div>
        <ul class="btn-wrap" style="z-index: 1001;" v-show="btnShow">
          <li class="light btn" @click="startMove">开始</li>
          <li class="night btn" @click="pauseMove">暂停</li>
          <li class="night btn" @click="resumeMove">继续</li>
        </ul>
        <el-collapse v-model="activeNames" class="collapse_box">
          <el-collapse-item title="车牌号和设备IMEI" name="1">
            <div style="padding: 2px 6px">
              <el-input
                placeholder="请输入车牌号或设备IMEI"
                v-model="pagination.searchWord"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="searchWord"
                ></el-button>
              </el-input>
            </div>
            <div>
              <ul
                class="electric_box"
                v-infinite-scroll="load"
                infinite-scroll-disabled="disabled"
                style="overflow:auto"
              >
                <li
                  v-for="(item, index) in tableData"
                  :class="isactive == index ? 'active' : ''"
                  class="infinite-list-item"
                  @click="searchitem(item, index)"
                  :key="index"
                >
                  <span
                    ><span style="font-weight: 600">设备IMEI: </span>
                    {{ item.IMEI }}</span
                  >
                  <span style="margin-left: 20px"
                    ><span style="font-weight: 600">备注: </span>
                    {{ item.Note }}</span
                  >
                </li>
                <p class="load_item" v-if="loadingScroll">加载中...</p>
                <p class="load_item" v-if="noMore">没有更多了</p>
              </ul>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import {
  getEquipDetail,
  ExportTrack,
  getEquipTrack,
  DelTrackInfo,
} from "@/api/platform/deviceSkim/index";
import { equipmentsList } from "@/api/platform/deviceManage/index";
import start from "../../../assets/static/start.png";
import end from "../../../assets/static/end.png";
import { reverseCoord } from "@/api/common/common";
import car from "../../../assets/static/car1.png";
export default {
  data() {
    return {
      // outerVisible: false,
      innerVisible: false,
      loading: false,
      type: 0, // 判断是查询点 还是查询轨迹 1是点 2是轨迹
      site: {
        lng: "",
        lat: "",
      }, // 中心点
      pagination: {
        //分页控件相关参数
        page: 1, //当前所处的页码
        total: 10, //总条数
        searchWord: "",
      },
      bindForm: {
        VehicleCarNumber: "", // 车牌号
        deviceNum: "", // 设备名
        time: [], // 时间
        status: "", // 状态
        deviceOwner: "", // 设备所有人
        DriverPhone: "", // 司机电话号码
      },
      options: [
        {
          value: "1",
          label: "启用",
        },
        {
          value: "2",
          label: "禁用",
        },
      ],
      stateList: [
        {
          id: 1,
          name: "是否启用",
          isActive: 1,
        },
        {
          id: 2,
          name: "是否激活",
          isActive: 1,
        },
        {
          id: 3,
          name: "是否过期",
          isActive: 1,
        },
        {
          id: 4,
          name: "ACG状态",
          isActive: 1,
        },
        {
          id: 5,
          name: "网络状态",
          isActive: 1,
        },
      ], // 设备状态列表
      //  设备详情
      deviceInfo: {},
      traceInfo: [], // 轨迹信息
      map: null, // 地图
      marker: null,
      infoWindow: null,
      btnShow: false, // 是否展示按钮组
      defaultTime: ["00:00:00", "23:59:59"], // 默认的选择时间
      points: [],
      activeNames: ["1"],
      loadingScroll: false,
      tableData: [],
      noMore: false,
      isactive: null,
      isBD: false,
      ismileage: false,
      mileageNum: 0,
      mileageNumBD: 0,
      trackKey: "",
    };
  },
  mounted() {
    this.initMap();
    // this.openMap()
  },
  created() {
    this.getNowTime();
    window.copyText = this.copyText;
    window.deleteText = this.deleteText;
  },
  computed: {
    disabled() {
      return this.loadingScroll || this.noMore;
    },
  },
  methods: {
    search(bindForm) {
      if (!this.bindForm.VehicleCarNumber) {
        this.$message.warning("请输入车牌号或设备IMEI");
        return;
      }
      if (this.bindForm.VehicleCarNumber && this.bindForm.time.length > 0) {
        this.getEquipTrack();
        // this.openMap()
      } else if (
        this.bindForm.VehicleCarNumber &&
        this.bindForm.time.length == 0
      ) {
        this.getEquipDetail();
      }
    },
    // 点击设备搜索
    searchitem(row, index) {
      this.bindForm.VehicleCarNumber = row.IMEI;
      this.isactive = index;
      this.getEquipDetail();
    },
    // 加载车牌设备
    load() {
      this.loadingScroll = true;
      let params = {
        pageIndex: this.pagination.page++,
        pageSize: 10,
        searchWord: this.pagination.searchWord,
      };
      equipmentsList(JSON.stringify(params))
        .then((res) => {
          this.tableData = this.tableData.concat(res.data.DataList);
          this.pagination.total = Number(res.data.TotalCount);
          if (
            Math.ceil(Number(res.data.TotalCount) / 10) < this.pagination.page
          ) {
            this.noMore = true;
          }
          this.loadingScroll = false;
        })
        .catch((err) => {
          if (err.code === 403) {
            this.$router.push("/power/nopermission");
          }
        });
    },
    searchWord() {
      this.pagination.page = 1;
      this.tableData = [];
      this.load();
    },
    resetForm(bindForm) {
      this.$refs[bindForm].resetFields();
      this.map.destroy();
      this.initMap();
      this.ismileage = false;
      this.mileageNum = 0;
      this.mileageNumBD = 0;
      this.isBD = 0;
    },
    // 获取设备详情
    getEquipDetail(item) {
      let params = {
        //imei: this.bindForm.deviceNum
        searchWord: this.bindForm.VehicleCarNumber || item,
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
      };
      this.loading = true;
      getEquipDetail(params)
        .then((res) => {
          this.deviceInfo = res.data;
          this.stateList[0].isActive = res.data.IsUse;
          this.stateList[1].isActive = res.data.IsActive;
          this.stateList[2].isActive = res.data.IsValid;
          this.stateList[3].isActive = res.data.ACGStatus;
          this.stateList[4].isActive = res.data.NetworkStatus;
          this.site.lng = res.data.Longitude;
          this.site.lat = res.data.Latitude;
          this.type = 1;
          this.btnShow = false;
          this.loading = false;
          this.ismileage = false;
          this.mileageNum = 0;
          this.mileageNumBD = 0;
          this.isBD = 0;
          this.setSite();
          // if (res.data.IMEI || res.data.VehicleNo  ) {
          //   this.outerVisible = true;
          // }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 处理选择时间
    handleTime(val) {
      var date = new Date();
      let data1 = new Date(val[0]).getTime();
      let data2 = new Date(val[1]).getTime();
      if (data2 - data1 > 86400000 * 10) {
        this.$message.warning("只能查询10天内的轨迹");
        this.bindForm.time = [];
        return;
      }
      this.getNowTime();
    },
    // 获取当前的时分秒
    getNowTime() {
      let time = new Date();
      let hh = time.getHours();
      let mf =
        time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
      let ss =
        time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
      this.defaultTime[1] = hh + ":" + mf + ":" + ss;
    },
    // 查询轨迹
    getEquipTrack() {
      let params = {
        searchWord: this.bindForm.VehicleCarNumber,
        stDate: this.bindForm.time[0],
        edDate: this.bindForm.time[1],
        coordType: 2, // 1是百度地图轨迹 2是腾讯地图轨迹
        redisKey: "",
      };
      this.loading = true;
      this.points = [];
      getEquipTrack(params)
        .then((res) => {
          this.isBD = res.data.isBD;
          this.mileageNum = res.data.mileageNum;
          this.mileageNumBD = res.data.mileageNumBD;
          this.trackKey = res.data.redisKey;
          this.ismileage = true;
          if (res.data.list.length > 0) {
            let list = res.data.list;
            this.site.lng = list[0].Lng;
            this.site.lat = list[0].Lat;
            let arr = list.map((item) => {
              return new TMap.LatLng(item.Lat, item.Lng);
            });
            this.traceInfo = arr;
            this.type = 2;
            this.btnShow = true;
            this.loading = false;
            // 1, list.length - 1
            let newArr = list.slice();
            newArr.forEach((item, index) => {
              this.points.push({
                id: index + 2,
                styleId:
                  index === 0
                    ? "start"
                    : index === newArr.length - 1
                    ? "end"
                    : "marker",
                position: new TMap.LatLng(item.Lat, item.Lng),
                Address: item.Address,
                properties: {
                  //自定义属性
                  time: item.GPSTime,
                  id: item.ID,
                },
              });
            });
            // 设置中心点
            this.setRoute();
          } else {
            this.$message.warning("该时间段暂无轨迹");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // ------------------------------------------------------------------------
    //  地图API
    initMap() {
      this.center = new TMap.LatLng(28.606169, 115.934794);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
    },
    // 设置车辆最新的位置
    setSite() {
      let _self = this;
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        //样式定义
        styles: {
          //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
          myStyle: new TMap.MarkerStyle({
            width: 46, // 点标记样式宽度（像素）
            height: 38, // 点标记样式高度（像素）
            src: car, //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            //点标注数据数组
            id: "demo",
            styleId: "marker",
            position: new TMap.LatLng(this.site.lat, this.site.lng),
            properties: {
              title: "marker",
            },
          },
        ],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      infoWindow.close(); //初始关闭信息窗关闭
      markerLayer.on("click", function(evt) {
        infoWindow.open(); //打开信息窗
        infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
        //设置信息窗内容
        infoWindow.setContent(`<div>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>${
            _self.deviceInfo.PrecisePosition
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
            _self.deviceInfo.Latitude
          }-${_self.deviceInfo.Longitude}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">物联设备：</span>${
            _self.deviceInfo.IMEI ? _self.deviceInfo.IMEI : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">设备所有人：</span>${
            _self.deviceInfo.EquipOwnerName
              ? _self.deviceInfo.EquipOwnerName
              : "暂无"
          }</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">车牌号/司机手机：</span>
          ${
            _self.deviceInfo.VehicleNo ? _self.deviceInfo.VehicleNo : "暂无"
          }/${_self.deviceInfo.DriverPhone ? _self.deviceInfo.DriverPhone : "暂无"}</p>
          <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">最后定位时间：</span>${
            _self.deviceInfo.GPSTime
          }</p>
          </div>`);
      });
    },
    // 查看车辆轨迹
    setRoute() {
      this.map.destroy();
      this.center = new TMap.LatLng(this.site.lat, this.site.lng);
      this.map = new TMap.Map("container", {
        center: this.center,
      });
      var polylineLayer = new TMap.MultiPolyline({
        map: this.map, // 绘制到目标地图
        // 折线样式定义
        styles: {
          style_blue: new TMap.PolylineStyle({
            color: "#3777FF", //线填充色
            width: 8, //折线宽度
            borderWidth: 2, //边线宽度
            borderColor: "#FFF", //边线颜色
            lineCap: "round", //线端头方式
            showArrow: true,
            arrowOptions: {
              width: 8,
              height: 8,
              space: 20,
            },
          }),
        },
        geometries: [
          {
            styleId: "style_blue",
            paths: this.traceInfo,
          },
        ],
      });
      this.marker = new TMap.MultiMarker({
        map: this.map,
        styles: {
          "car-down": new TMap.MarkerStyle({
            width: 40,
            height: 40,
            anchor: {
              x: 20,
              y: 20,
            },
            faceTo: "map",
            rotate: 180,
            src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/car.png",
          }),
          start: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: start,
          }),
          end: new TMap.MarkerStyle({
            width: 25,
            height: 35,
            anchor: { x: 16, y: 32 },
            src: end,
          }),
          marker: new TMap.MarkerStyle({
            width: 18,
            height: 25,
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "car",
            styleId: "car-down",
            position: this.traceInfo[0],
          },
          {
            id: "start",
            styleId: "start",
            position: this.traceInfo[0],
          },
          {
            id: "end",
            styleId: "end",
            position: this.traceInfo[this.traceInfo.length - 1],
          },
          ...this.points,
        ],
      });
      //初始化mark标记点点击事件
      var infoWindow = new TMap.InfoWindow({
        map: this.map,
        position: new TMap.LatLng(0, 0),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素，为了使其显示在Marker的上方
      });
      this.infoWindow = infoWindow;
      infoWindow.close(); //初始关闭信息窗关闭

      //监听标注点击事件
      this.marker.on("click", function(evt) {
        let params = {
          lat: evt.geometry.position.lat,
          lng: evt.geometry.position.lng,
        };
        reverseCoord(params).then((res) => {
          let isAdmin = localStorage.getItem("isAdmin");
          //设置infoWindow
          infoWindow.open(); //打开信息窗
          infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
          //设置信息窗内容
          let str = `<div style="text-align:left;">
            <p style="text-align:left;font-size:15px;margin:0;display:inline-block">
              <span id="text">${evt.geometry.properties.id}</span>
              <textarea id="input" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
              <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(1)">复制</span>
            </p>`;
          if (!!Number(isAdmin)) {
            str += `<span style="color:#409eff;cursor:pointer;margin-left:10px;" onclick="deleteText(${evt.geometry.id}, ${evt.geometry.properties.id})">删除</span>`;
          }
          str += `<p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">地址：</span>
                <span id="text1">${res.data.address}</span>
                <textarea id="input1" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
                <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(2)">复制</span>
              </p>
              <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">经纬度：</span>${
                evt.geometry.position.lng
              }-${evt.geometry.position.lat}</p>
              <p style="text-align:left;font-size:15px;margin:10px 0;"><span style="font-weight:700">时间：</span>
                <span id="text2">${
                  evt.geometry.properties.time.split("／")[0]
                } ${evt.geometry.properties.time.split("／")[1]}</span>
                <textarea id="input2" style="position:absolute;top:0;left:0;opacity:0;z-index:-10;"></textarea>
                <span style="color:#409eff;margin-left:10px;cursor:pointer" onclick="copyText(3)">复制</span>
              </p>
            </div>`;
          infoWindow.setContent(str);
        });
      });

      // this.startMove()
    },
    copyText(type) {
      let text = "";
      let input = "";
      if (type == 1) {
        text = document.getElementById("text").innerText;
        input = document.getElementById("input");
      } else if (type == 2) {
        text = document.getElementById("text1").innerText;
        input = document.getElementById("input1");
      } else {
        text = document.getElementById("text2").innerText;
        input = document.getElementById("input2");
      }
      input.value = text; // 修改文本框的内容
      input.select(); // 选中文本
      document.execCommand("copy"); // 执行浏览器复制命令
      this.$message.success("复制成功");
    },
    // 删除marker点
    deleteText(id, rowId) {
      let text = document.getElementById("text").innerText;
      this.$confirm("是否确认删除当前标记点", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            id: text,
            trackKey: this.trackKey,
          };
          DelTrackInfo(params).then((res) => {
            this.marker.remove(id);
            this.infoWindow.close();
          });
        })
        .catch(() => {});
    },
    //开始移动
    startMove() {
      this.marker.moveAlong(
        {
          car: {
            path: this.traceInfo,
            speed: 5000,
          },
        },
        {
          autoRotation: true,
        }
      );
    },
    //停止移动
    pauseMove() {
      this.marker.pauseMove();
    },
    //重新开始
    resumeMove() {
      this.marker.resumeMove();
    },
    download() {
      let params = {
        searchWord: this.bindForm.VehicleCarNumber,
        stDate: this.bindForm.time[0],
        edDate: this.bindForm.time[1],
      };
      if (!this.bindForm.VehicleCarNumber || this.bindForm.time.length === 0) {
        this.$message.warning("请输入车牌号或设备IMEI及时间范围");
        return;
      }
      ExportTrack(params).then((res) => {
        window.open(res.data);
      });
    },
  },
};
</script>
<style lang="scss">
.deviceSkim {
  .circle {
    display: inline-block;
    width: 55px;
    height: 55px;
    background: green;
    border-radius: 50%;
  }
}
</style>
<style lang="scss" scoped>
.deviceSkim {
  display: flex;
  flex-direction: column;
  .mileage {
    position: absolute;
    top: 30px;
    right: 50px;
    color: #303133;
  }
  .deviceForm {
    width: 85%;
  }
  .strengthen {
    margin-top: 20px;
    .imgs {
      margin-right: 10px;
    }
  }
  ul li {
    list-style: none;
  }
  .btn-wrap {
    z-index: 999;
    position: fixed;
    bottom: 10.5rem;
    margin-left: 3rem;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    background-color: #fff;
    box-shadow: 0 2px 6px 0 rgba(27, 142, 236, 0.5);
  }
  .btn {
    width: 75px;
    height: 30px;
    float: left;
    background-color: #fff;
    color: rgba(27, 142, 236, 1);
    font-size: 14px;
    border: 1px solid rgba(27, 142, 236, 1);
    border-radius: 5px;
    margin: 0 5px;
    text-align: center;
    line-height: 30px;
  }
  .btn:hover {
    background-color: rgba(27, 142, 236, 0.8);
    color: #fff;
  }
  .map {
    width: 100%;
    height: 1000px;
  }
  .circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
  }
  .outerBox {
    display: flex;
    flex-direction: column;
    padding-left: 10%;
    .top {
      display: flex;
      // justify-content: space-around;
      margin-bottom: 20px;
      .device {
        margin-right: 60px;
      }
    }
  }
  .innerBox {
    display: flex;
    .state {
      width: 20%;
    }
  }
}
.pageTitle {
  display: inline-block;
  margin-right: 16px;
}
.topView {
  position: relative;
}
.collapse_box {
  position: absolute;
  top: 50px;
  left: 40px;
  z-index: 1001;
  width: 300px;
  background-color: #fff;
  border: none;
  .electric_box {
    height: 300px;
    overflow-y: auto;
    padding: 0px 6px;
    .infinite-list-item {
      height: 36px;
      line-height: 36px;
      border-bottom: 1px solid #eee;
      cursor: pointer;
    }
    .infinite-list-item:hover {
      background-color: #ddd;
    }
    .active {
      background-color: #ddd;
    }
    .load_item {
      text-align: center;
      margin-top: 6px;
      font-size: 12px;
    }
  }
  .electric_box::-webkit-scrollbar {
    width: 4px;
  }
  .electric_box::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }
  .electric_box::-webkit-scrollbar-thumb {
    background-color: #eee;
  }
  .el-collapse-item {
    box-sizing: border-box;
  }
  ::v-deep .el-collapse-item__header {
    text-align: center;
    display: block;
    background-color: #409eff;
    color: #fff;
    font-size: 14px;
    border-radius: 4px;
    .el-collapse-item__arrow {
      float: right;
      margin-top: 16px;
      margin-right: 16px;
    }
  }
}
#input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
</style>
